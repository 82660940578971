import axios from "axios";
//! Parte constante
const data = require("../../public/json/settings.json");
const URL = data[0].dataUrl;
// Si cargaApi es true cargara de la api y si es false cargara de los JSON
var cargaApi = true;

//! Parte variable
//* Datos del usuario
const URLprofile = "profile?token=";

//! Endpoints
//* Tabla de albaranes
const APIprofile = cargaApi
  ? `${URL}` + `${URLprofile}`
  : "../../json/profile.json";

//! Llamadas a las API´s
export default {
  profile: {
    get(token, filter) {
      return axios.get(APIprofile + token + "&filter=" + filter);
    },
    create() {
      return axios.post(APIprofile);
    }
  }
};
