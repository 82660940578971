import Vue from "vue";
import App from "../App";
import router from "../router";
import Swal from "sweetalert2/dist/sweetalert2.js";
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

// Posibles errores de validación de token, y filtros de bits
const noToken = 0x0004; // 	No se ha recibido el token
const tokenError = 0x0008; // El token no es válido
const tokenIsExpired = 0x000c; // El token está expirado
const noPermissions = 0x0010; // No tiene permisos para realizar la acción
const tokenRenew = 0x0020; // Debe renovar el token
const filterToken = 0x003c; // Filtro con todos los valores posibles

// función que comprueba si el token es válido o no según si existe un error
// en las respuestas a las peticiones al backend y devuelve un mensaje de error
// al usuario. Se redirecciona al login. Esta función es llamada en cada petición.
export function checkToken(result) {
  let error = result.data.error & filterToken;
  let renew = (error & tokenRenew) != 0;
  if (renew) {
    renewToken();
  }
  if (error === noToken || error === tokenError || error === tokenIsExpired) {
    Swal.fire({
      title: result.data.message,
      icon: "error",
      confirmButtonText: "Aceptar",
      background: "black",
      confirmButtonColor: "transparent",
      timer: 3000,
      willClose: setTimeout(
        () => localStorage.removeItem("token"),
        localStorage.removeItem("user"),
        router.push({
          path: "login"
        }),
        3000
      )
    });
    return false;
  } else if (error === noPermissions) {
    Swal.fire({
      title: result.data.message,
      icon: "error",
      confirmButtonText: "Aceptar",
      background: "black",
      confirmButtonColor: "transparent",
      timer: 3000
    });
    return false;
  }
  return true;
}

function renewToken() {
  console.log('Realizar petición al servidor de un token actualizado');
}
