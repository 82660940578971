var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !_vm.isLoading &&
      _vm.$route.path !== '/login' &&
      _vm.$route.path !== '/crear-albaran' &&
      _vm.$route.path !== '/gestionar-albaran' &&
      _vm.$route.path !== '/firmar-albaran' &&
      _vm.$route.path !== '/albaran' &&
      _vm.$route.path !== '/LPDT' &&
      !_vm.tecnico &&
      !_vm.cliente
  )?_c('div',[_c('sidenav',{class:_vm.curClasses,attrs:{"orientation":_vm.orientation}},[_c('div',{staticClass:"sidenav-inner",class:{ 'py-1': _vm.orientation !== 'horizontal' }},[_c('sidenav-menu',{attrs:{"icon":"fa-solid fa-buildings","active":_vm.isMenuActive('/views/sedes'),"open":_vm.isMenuOpen('/views/sedes')}},[_c('template',{slot:"link-text"},[_vm._v("Agencias")]),_vm._v(" "),_vm._l((_vm.menu.menu.franchisees),function(sede,index){return _c('div',{key:sede.id},[_c('sidenav-router-link',{attrs:{"id":index,"icon":"fa-solid fa-warehouse","to":{ path: '/albaranes/?franquicia=' + sede.id },"exact":true},nativeOn:{"click":function($event){return _vm.InlineButtonClickHandler(sede.id)}}},[_vm._v(_vm._s(sede.name)+"\n          ")])],1)})],2),_vm._v(" "),_c('sidenav-router-link',{attrs:{"icon":"fa-solid fa-anchor","active":_vm.isMenuActive('/views/barcos'),"to":"/barcos"}},[_vm._v("\n        Barcos\n      ")]),_vm._v(" "),(_vm.franquiciado || _vm.administrador)?_c('sidenav-menu',{attrs:{"icon":"fa-solid fa-users","active":_vm.isMenuActive('/views/usuarios'),"open":_vm.isMenuOpen('/views/usuarios')}},[_c('template',{slot:"link-text"},[_vm._v("Usuarios")]),_vm._v(" "),(!_vm.franquiciado)?_c('sidenav-router-link',{attrs:{"to":"/franquiciados","icon":"fa-solid fa-user","exact":true}},[_vm._v("Agentes")]):_vm._e(),_vm._v(" "),_c('sidenav-router-link',{attrs:{"to":"/tecnicos","icon":"fa-solid fa-user","exact":true}},[_vm._v("Técnicos")]),_vm._v(" "),_c('sidenav-router-link',{attrs:{"to":"/clientes","icon":"fa-solid fa-user","exact":true}},[_vm._v("Clientes")])],2):_vm._e(),_vm._v(" "),(_vm.franquiciado || _vm.administrador)?_c('sidenav-router-link',{attrs:{"icon":"fa-solid fa-table-list","active":_vm.isMenuActive('/views/barcos'),"to":"/partes-trabajo"}},[_vm._v("\n        Partes de trabajo\n      ")]):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }