<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <app-layout-navbar v-if="$route.path != '/login'" />

      <div class="layout-container">
        <app-layout-sidenav
          v-on:franquiciaID="franquiciaID"
          :franquiciaNueva="franquiciaNueva"
          v-if="$route.path != '/login'"
        />

        <div class="layout-content">
          <div v-if="$route.path == '/login'" class="router-transitions">
            <router-view />
          </div>
          <div
            v-else
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <!-- se propaga la prop franquicia que envia el id de la franquicia clickada en
          el sidenav para que la reciba la hoja de albranes -->
            <router-view :franquicia="franquicia" v-on:franquiciaAct="franquiciaAct" />
          </div>

          <app-layout-footer v-if="$route.path != '/login'" />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'

export default {
  name: 'app-layout-1',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter
  },
  data() {
    return {
      franquicia: Number,
      franquiciaNueva: Boolean
    }
  },
  mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy() {
    this.layoutHelpers.destroy()
  },

  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true)
    },
    // EVENTOS QUE SE RECIBEN
    // se recibe el id de la franquicia clickada a través del evento
    // franquiciaID emitido por sidenav. Este evento lo escucha el watch de alabaranes
    // y llama a los albaranes de la franquicia clickada.
    franquiciaID(datos) {
      this.franquicia = datos;
    },
    // se recibe el objeto con la nueva franquicia al crear franquicia en profile.
    // Este evento será escuchado por el watch de sidenav para actualizar la lista de franquicias
    franquiciaAct(datos) {
      console.log(datos);
      this.franquiciaNueva = datos;
    },
  }
}
</script>
