import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import Layout1 from "@/layout/Layout1";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: Layout1,
      children: [
        {
          path: "login",
          component: () => import("@/views/Login")
        },
        {
          path: "profile",
          component: () => import("@/views/usuarios/Profile")
        },
        {
          path: "tecnicos",
          component: () => import("@/views/usuarios/Tecnicos")
        },
        {
          path: "barcos",
          component: () => import("@/views/barcos/Barcos")
        },
        {
          path: "clientes",
          component: () => import("@/views/usuarios/Clientes")
        },
        {
          path: "franquiciados",
          component: () => import("@/views/usuarios/Franquiciados")
        },
        {
          path: "detalle-barco",
          component: () => import("@/views/barcos/DetalleBarco")
        },
        {
          path: "albaranes",
          name: "albaranes",
          component: () => import("@/views/albaranes/Albaranes")
        },
        {
          path: "albaran",
          component: () => import("@/views/albaranes/Albaran")
        },
        {
          path: "LPDT",
          component: () => import("@/views/usuarios/LPDT")
        },
        {
          path: "albaranes/crear-albaran",
          component: () => import("@/components/albaranes/CrearAlbaran")
        },
        {
          path: "albaranes/gestionar-albaran",
          component: () => import("@/components/albaranes/GestionarAlbaran")
        },
        {
          path: "albaranes/firmar-albaran",
          component: () => import("@/components/albaranes/Firma")
        },
        {
          path: "albaranes/crear-orden-trabajo",
          component: () =>
            import("@/components/ordenesTrabajo/crearOrdenTrabajo")
        },
        {
          path: "/partes-trabajo",
          component: () =>
            import("@/views/partesTrabajo/PartesTrabajo")
        }
      ]
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
