<template>
  <div v-if="$route.path !== '/login'">
    <b-navbar
      toggleable="lg"
      :variant="getLayoutNavbarBg()"
      class="layout-navbar align-items-lg-center container-p-x bg-primary"
    >
      <!-- Brand -->
      <b-navbar-brand>
        <img src="/images/logo-repamar-50.jpg" height="40"
      /></b-navbar-brand>

      <!-- Sidenav toggle -->
      <b-navbar-nav
        class="align-items-lg-center mr-auto mr-lg-4"
        v-if="sidenavToggle"
      >
        <a
          class="nav-item nav-link px-0 ml-2 ml-lg-0"
          href="javascript:void(0)"
          @click="toggleSidenav"
        >
          <i class="ion ion-md-menu text-large align-middle" />
        </a>
      </b-navbar-nav>

      <!-- Navbar toggle -->
      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse is-nav id="app-layout-navbar">
        <b-navbar-nav class="align-items-lg-center">
          <b-nav-item
            v-if="rol == 'Administrador' || rol == 'Franquiciado'"
            href="/profile"
            >Usuario: <strong>{{ nombre }}</strong></b-nav-item
          >
          <b-nav-item v-if="rol == 'Cliente'" href="/barcos"
            >Usuario: <strong>{{ nombre }}</strong></b-nav-item
          >
          <b-nav-item v-if="rol == 'Técnico'" href="/albaranes"
            >Usuario: <strong>{{ nombre }}</strong></b-nav-item
          >
          <b-nav-item @click="cerrarSesion">Cerrar Sesión</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // array de objetos que recibimos de la bbdd con los datos que queremos mostrar
      nombre: "",
      rol: "",
    }
  },
  methods: {
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    },
    cerrarSesion() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$router.push({
        path: '/login'
      });
    }
  },
  created() {
    const user = localStorage.getItem("user");
    const rol = localStorage.getItem("role");
    this.nombre = user;
    this.rol = rol;
  }
}
</script>
