<template>
  <div
    v-if="
      !isLoading &&
        $route.path !== '/login' &&
        $route.path !== '/crear-albaran' &&
        $route.path !== '/gestionar-albaran' &&
        $route.path !== '/firmar-albaran' &&
        $route.path !== '/albaran' &&
        $route.path !== '/LPDT' &&
        !tecnico &&
        !cliente
    "
  >
    <sidenav :orientation="orientation" :class="curClasses">
      <!-- Inner -->
      <div
        class="sidenav-inner"
        :class="{ 'py-1': orientation !== 'horizontal' }"
      >
        <sidenav-menu
          icon="fa-solid fa-buildings"
          :active="isMenuActive('/views/sedes')"
          :open="isMenuOpen('/views/sedes')"
        >
          <template slot="link-text">Agencias</template>
          <div v-for="(sede, index) in menu.menu.franchisees" :key="sede.id">
            <!-- @click.native manda el id de la sede a la función para que esta emita un evento -->
            <sidenav-router-link
              :id="index"
              icon="fa-solid fa-warehouse"
              :to="{ path: '/albaranes/?franquicia=' + sede.id }"
              @click.native="InlineButtonClickHandler(sede.id)"
              :exact="true"
              >{{ sede.name }}
            </sidenav-router-link>
          </div>

          <!-- VER TALLERES DENTRO DE CADA FRANQUICIA => SIN USO YA QUE LOS TALLERES COMPARTEN ALBARANES -->
          <!-- <sidenav-menu
            v-for="sede in menu.menu.franchisees"
            :key="sede.id"
            icon="fa-solid fa-building"
            :exact="true"
          >
            <template slot="link-text">{{ sede.name }}</template>
            <sidenav-router-link
              v-for="taller in sede.headquarters"
              :key="taller.id"
              icon="fa-solid fa-warehouse"
              :to="'/albaranes?franquicia=' + sede.id"
              :exact="true"
            >
              {{ taller.name }}
            </sidenav-router-link>
          </sidenav-menu> -->
        </sidenav-menu>
        <sidenav-router-link
          icon="fa-solid fa-anchor"
          :active="isMenuActive('/views/barcos')"
          to="/barcos"
        >
          Barcos
        </sidenav-router-link>
        <sidenav-menu
          v-if="franquiciado || administrador"
          icon="fa-solid fa-users"
          :active="isMenuActive('/views/usuarios')"
          :open="isMenuOpen('/views/usuarios')"
        >
          <template slot="link-text">Usuarios</template>

          <sidenav-router-link
            v-if="!franquiciado"
            to="/franquiciados"
            icon="fa-solid fa-user"
            :exact="true"
            >Agentes</sidenav-router-link
          >
          <sidenav-router-link
            to="/tecnicos"
            icon="fa-solid fa-user"
            :exact="true"
            >Técnicos</sidenav-router-link
          >
          <sidenav-router-link
            to="/clientes"
            icon="fa-solid fa-user"
            :exact="true"
            >Clientes</sidenav-router-link
          >
        </sidenav-menu>
        <sidenav-router-link
          v-if="franquiciado || administrador"
          icon="fa-solid fa-table-list"
          :active="isMenuActive('/views/barcos')"
          to="/partes-trabajo"
        >
          Partes de trabajo
        </sidenav-router-link>
      </div>
    </sidenav>
  </div>
</template>

<script>
const data = require("../endpoints/EP_profile");
import { checkToken } from "../utilidades/CheckToken.js";
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: ["orientation", "franquiciaNueva"],

  data() {
    return {
      // array de objetos que recibimos de la bbdd con los datos que queremos mostrar
      menu: [],
      isLoading: true,
      cliente: false,
      franquiciado: false,
      administrador: false,
      tecnico: false,
    };
  },
  watch: {
    orientation: {
      type: String,
      default: "vertical"
    },
    // watcher que detecta el evento franquiciaNueva que llega desde layout1 y que emite profile
    // al añadir, editar o eliminar uyna franquicia
    // el watch recarga la lista de franquicias del sidenav.
    franquiciaNueva: function(newValue, oldValue) {
      console.log("nuevoCreada", newValue);
      console.log("viejoCreada", oldValue);
      const token = localStorage.getItem("token");
      data.default.profile.get(token).then(result => {
        console.log("Recarga de lista de franquicias", result);
        checkToken(result);
        // Se actualiza el menu de franquicias
        this.menu.menu.franchisees = result.data.data.menu.franchisees;
        console.log(this.menu.menu.franchisees);
      });
    }
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },
  methods: {
    // función que emite el evento franquiciaID cuando se hace click en alguna franquicia,
    // enviando a layout1 el id de la franquicia clickada
    InlineButtonClickHandler(event) {
      this.$emit("franquiciaID", event);
    },
    albaranes(sede) {
      this.$router.push("/albaranes" + "?franquicia=" + sede);
    },
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    }
  },
  async created() {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const filter = "sidenav";
    //? LLAMADA AL ENDPOINT Y CARGA DE DATOS DE ALBARANES EN OBJETO profile
    try {
      await data.default.profile.get(token, filter).then(result => {
        console.log(result);
        console.log("Sedes, barcos y usuarios");
        checkToken(result);
        // Se almacenan los datos del usuario en la variable profile
        this.menu = result.data.data;
        // ROLES:
        // Administrador
        // Franquiciado
        // Tecnico
        // Cliente
        if (role == "Administrador") {
          this.administrador = true;
        } else if (role == "Franquiciado") {
          this.franquiciado = true;
        } else if (role == "Técnico") {
          this.tecnico = true;
        } else if (role == "Cliente") {
          this.cliente = true;
        }
        this.isLoading = false;
      });
    } catch (e) {
      console.error(e);
    }
  }
};
</script>
